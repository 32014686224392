import CONST, { AUTH_SAFE_CUSTODY_LEVEL } from '@/config/const';
import { ucApi, userApi, assetApi, subApi, vipApi, hskFeeApi, etfApi, authorizedAdminApi, rfqApi } from '@/config/api';
import { kycApi } from '@/config/kyc';
import { stayRoute } from '@/config/setup';
import { getOriginPath } from '@/lang/i18n';
import { removeCookie } from '@/utils/cookie';
import { toast } from '@/utils/toast';
import { WorkerTimer } from '@/utils/worker';
import { useAssetsStore } from './assets';
import { useOrderStore } from './order';
interface Userinfo {
  userId: string;
  registerType: number;
  mobile: string;
  nationalCode: string;
  email: string;
  bindGA: boolean;
  bindTradePwd: boolean;
  bindPassword: boolean;
  userType: number;  // 身份类型: 1. 零售 2. 机构 3. 个人（PI） 4. 综合账户 5. 交易员子账号 6. 授权交易员 7.临时身份
  masterUserType: number; // 创建该账户的父账户类型
  verifyStatus: number;
  kycLevel: number;
  kycVerifyStatus: number;
  kycStatus: number;
  displayLevel: string;
  accounts: [];
  defaultAccountId: string;
  registerDate: string;
  openOption: boolean;
  openFuture: boolean;
  openMargin: boolean;
  learnWithdraw: boolean;
  learnDeposit: boolean;
  riskSettings: any;
  favorites: [];
  source: string;
  nickname: string;
  isAgent: number;
  customLabelList: [];
  levelNames: [];
  leader: number;
  apiStatus: number;// 0没权限 1有 创建api key的权限
  apiConfirm: number;// 0未同意创建apikey协议 1 创建过api key 有同意协议
  isHobbit: number;
  antiPhishingCode: string;
  isComplianceVerify: boolean;
  lastLoginDate: string;
  avatar: string;
  masterUserId: string;
  agentStatus: number;
  inInviteBlack: boolean;
  clientName?: string;
  badge: number;
  etfType?:number;// 0 非etf用户 1 etf托管用户 2 PD omnibus综合账户
  tempEtf?:number;// 0 零售身份 1 etf身份
  /**
   * 是否RFQ灰度用户, 0 非灰度用户, 1 灰度用户
   * @deprecated HKEX-11019 待开放全部访问权限后，此字段将不再使用
   * */
  tmpRfq?: 0 | 1;
}
interface LoginUser {
  userId: string;
  registerType: number;
  mobile: string;
  nationalCode: string;
  email: string;
  bindGA: boolean;
  bindTradePwd: boolean;
  bindPassword: boolean;
  userType: number;
  verifyStatus: number;
  kycLevel: number;
  kycVerifyStatus: number;
  kycStatus: number;
  displayLevel: string;
  accounts: [];
  defaultAccountId: string;
  registerDate: string;
  source: string;
  inviteUserId: string;
  secondLevelInviteUserId: string;
  isAgent: number;
  customLabelList: [];
  antiPhishingCode: string;
  isComplianceVerify: false;
  lastLoginDate: string;
  lastLoginIp: string;
  platfrom: string;
}

interface KycInfo {
  openChannel: number;
  personal: {
    assetsProofStatus: {
      auditStatus: number;
    },
    bankApplyStatus: {
      auditStatus: number;
    },
    bankCardStatus: {
      auditStatus: number;
    },
    bankProofStatus: {
      auditStatus: number;
    },
    bankTransferStatus: {
      auditStatus: number;
    },
    baseInfoApplyStatus: {
      auditStatus: number;
    },
    certStatus: {
      auditStatus: number;
    },
    financialStatus: {
      auditStatus: number;
    },
    personalStatus: {
      auditStatus: number;
    },
    riskAssessmentStatus: {
      auditStatus: number;
    },
    signatureStatus: {
      auditStatus: number;
    },
    supplementaryStatus: {
      auditStatus: number;
    },
    vaAndRiskStatus: {
      auditStatus: number;
    },
    vaTestStatus: {
      auditStatus: number;
    },
  },
  supportRetail: boolean,
  totalAuditStatus: number,
  userType: number
}

interface VerifyInfo {
  current_level: number,
  displayLevel: string,
  verifyStatus: number,
  refusedReason: string,
}

interface BindInfo {
  bindEmail: boolean,
  bindGA: boolean,
  bindMobile: boolean,
  defaultAuthType: string,
  need2FA: boolean,
}
// 用户HSK手续费相关信息 (需要登录)

// 如果是OPT账户，查询是否有待结算RFQ金额
export type PendingSettleInfo = {
  balanceList: {
    tokenId: string,
    amount: string,
    amountUsd: string
  }[];
  orderCount: number;
  totalUsd: string;
}
export interface UserHskFeeInfo {
  whiteListFlag: boolean; // 是否是白名单，只有在白名单内才显示HSK入口
  vipFlag: boolean; // 是否使用vip费率
  vipLevelId: number; // 当前vip等级
  vipLevelName: string; // 当前等级名称[VIP0~6] （特殊费率需特殊处理）
  tmpVipLevelId: number; // 可以升级到的vip等级, 与 nextLevelId 可能不同(允许跨级升级)
  tmpVipLevelName: string; // 可以升级到的vip等级名称
  upgradeFlag: boolean; // 立即升级标识 [手动升级过一次后，便为false，默认值为true]
  specialFee: boolean; // 是否是特殊费率
  tradeUsdAmount: string; // T+1: 交易量usd
  assetUsdAmount: string; // T+1: 资产余额usd
  calDate: string; // 交易量更新时间
  meetUpgrade: boolean; // 升级按钮是否高亮 (交易量达到升级条件才会高亮)
  fee: string; // 当前手续费 [当前用户等级，是展示fee, 还是展示 maker，taker, 由 fee 字段决定，如果 fee 字段存在且有值，则展示 fee，否则展示 maker/taker]
  originalFee: string; // 原始手续费
  makerFee: string;  // 当前maker费率
  originalMakerFee: string; // 原始maker费率
  takerFee: string; // 当前taker费率
  originalTakerFee: string; // 原始taker费率
  nextLevelId: number;  // 下一等级  [nextLevelId 与 vipLevelId 相等时，代表已是最高等级]
  nextLevelName: string; // 下一等级名称
  nextLevelGapTradeUsdAmount: string; // 升级还需要的现货交易量
  nextLevelFee: string; // 下一等级费率  [下一等级，是展示fee 还是展示 maker/taker,由 nextLevelFee 字段决定，如果 nextLevelFee 字段存在且有值，则展示 fee，否则是 maker/taker]
  nextLevelMakerFee: string; // 下一等级maker费率
  nextLevelTakerFee: string; // 下一等级taker费率
  cardType: number; // -1无卡  0金卡  1银卡
  cardEndTime: string; // 金卡/银卡过期时间
  cardPush: boolean; // 进入是否进行金卡/银卡弹窗展示 true：弹出，false：不弹
  hskDeductionSwitch: number; // 是否开启HSK抵扣功能 0: 未开启 1: 已开启
}

const assetsStore = useAssetsStore();
const orderStore = useOrderStore();

var lastRefresh = new Date();

export const useUserStore = defineStore('userStore', {
  state: () => {
    // 从localstorage 读取切换的子账户
    const storeSubAccountId = localStorage.getItem('sub_account_list') ? localStorage.getItem('sub_account') as string : '';
    // 从localstorage 读取forbidden ip信息
    const forbiddenMsg = localStorage.getItem('forbidden-ip') as string;
    return {
      userinfo: {} as Userinfo,
      baseUserInfo: {} as LoginUser,
      countriesList: [] as any,
      countryList: [] as any,
      regCountriesList: [] as any,
      unRegCountriesList: [] as any,
      regDeviceInfo: {} as any,
      // kyc信息
      kycInfo: {} as KycInfo,
      // kyc验证信息
      verifyInfo: {} as VerifyInfo,
      // 子账户
      subAccount: <any>null,
      // 当前子账户资产
      subAssets: [] as [],
      // 子账户map
      subAccountMap: <any>{},
      // vpn
      vpnWarningInfo: <any>{},
      // 子账户资产轮循控制器
      timeSubAsset: <any>null,
      // 登录状态定时器
      loginStateTimer: <any>null,
      // 登录状态是否过期
      loginStatusExpire: <boolean>false,
      // 在其他设备登录
      loginOtherEquipment: <boolean>false,
      // 邀请码
      inviteCode: <string>'',
      // 新版-子账户列表
      subAccountList: [] as any,
      currentSubAccountId: storeSubAccountId,
      refresh: <number>0,
      // api权限列表
      apiKeyAuthList: [] as any,
      levelInfo: {} as any,
      // 授权交易员列表
      operatorList: [] as any,
      // 各数据的加载状态
      loading: {
        userInfo: false
      },
      // 是否是opt账户
      isOptInited: <any>false,
      isOPTAccount: <any>false,
      optPendingSettleInfo: {} as PendingSettleInfo,
      // 是否是处置账户
      isDisposalAccount: <any>false,
      // 用户活跃度过期时长
      activeOutTime: <any>{
        tradePage: 360, // 单位分钟，交易页面time1会话超时时间默认6小时
        tradePageV2: 360, // 单位分钟，交易页面time2会话超时时间默认6小时
        notTradePage: 120, // 单位分钟，非交易页面time1会话超时时间默认6小时
        notTradePageV2: 120 // 单位分钟，非交易页面time2会话超时时间默认6小时
      },
      // 当前time1/time2会话
      activeCurrentTime: <number>1, // 1->time1, 2->time2, 3-> time2倒计时结束
      // 用户活跃度定时器计数
      activeCount: <number>0,
      // 用户活跃度检测定时器
      activeTimer: <any>null,
      // 超时提示
      activeOutStatus: <boolean>false,
      // 是否交易页面
      isTradePage: <boolean>false,
      // 用户HSK手续费等级相关信息
      userHskFeeInfo: {} as UserHskFeeInfo,
      // forbidden ip信息
      forbiddenIp: forbiddenMsg,
      // 授权管理员是否有主账户赋权
      hasMainPermission: false,
      mainPermissionBaseInfo: <any>{},
      permissions: [] as any,
      bindInfo: {} as BindInfo
    };
  },
  getters: {
    isUnPhoneReg(state): boolean {
      // 判断当前用户的ip在禁止手机号码注册的国家列表中
      if (state.unRegCountriesList.length > 0 && state.regDeviceInfo.countryCode) {
        let index = -1;
        for (let i = 0; i < state.unRegCountriesList.length; i++) {
          if (state.unRegCountriesList[i]?.shortName == state.regDeviceInfo.countryCode) {
            index = i;
            break;
          }
        }
        if (index >= 0) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    isLogin(state): boolean {
      return !!state.userinfo.userId;
    },
    mountedSubAccount(state): any {
      if (state.subAccountList && state.subAccountList.length > 0) {
        // item.trader.userIdStr === state.userinfo.userId 只返回了主账户名下的子账户，已经绑定授权交易员的子账户被过滤掉了
        return state.subAccountList.filter((item: any) => item.trader?.userIdStr === state.userinfo.userId);
      }
      return [];
    },
    currentSubAccount(state): any {
      const localSubAccountListStr  = localStorage.getItem('sub_account_list');

      if (localSubAccountListStr) {
        try {
          this.subAccountList = JSON.parse(localSubAccountListStr);
        } catch (error) {
          console.log('error', 'localSubAccountListStr 解析失败');
        }
      }

      const { userinfo, subAccountList } = state;
      let { currentSubAccountId } = state;

      if (!currentSubAccountId) {
        currentSubAccountId = localStorage.getItem('sub_account') as string;
      }

      if (currentSubAccountId) {
        var list = subAccountList.filter((item: any) => item.userIdStr === currentSubAccountId);
        if (list && list.length > 0) {
          return subAccountList.filter((item: any) => item.userIdStr === currentSubAccountId)[0];
        } else {
          localStorage.removeItem('sub_account');
        }
      }

      if (userinfo.masterUserId == '0') {
        // 主账户
        return { nickname: userinfo.nickname, userIdStr: userinfo.userId, defaultAccountIdStr: userinfo.defaultAccountId };
      } else if (this.isAuthorizedAdmin && this.hasMainPermission) {
        // 授权管理员
        localStorage.setItem('ws_sub_account', this.mainPermissionBaseInfo.userIdStr); // 订阅需要授权管理员的userid
        return this.mainPermissionBaseInfo;
      } else {
        // 授权交易员
        var result = [];
        if (subAccountList.length > 0) {
          result = subAccountList[0];
          localStorage.setItem('sub_account', result.userIdStr);
        }

        return result;
      }
    },
    isKyc(state) {
      return state.userinfo?.kycVerifyStatus === 2;
    },
    // 通过kyc
    isKycPass(state) {
      return state.userinfo?.kycStatus === CONST.kycStatus.PASS;
    },
    // 未通过kyc
    isNotKycPass(state) {
      return typeof state.userinfo?.kycStatus === 'number' && state.userinfo?.kycStatus !== CONST.kycStatus.PASS;
    },
    // 获取kyc信息
    getKycInfo(state) {
      const { kycInfo, verifyInfo } = state;
      // return kycInfo;
      if (!Object.keys(kycInfo).length) {
        return {};
      }

      if (!Object.keys(verifyInfo).length) {
        return kycInfo;
      }
      /**
       * needVedioVerify 三级kyc是否打开
       * userKycinfo: kyc各级别信息
       * currentLevelInfo 当前级别信息
       */
      const obj: any = {
        ...kycInfo
        // currentLevelInfo: kycInfo.userKycinfo.find(
        //   (data: any) => data.displayLevel == verifyInfo.displayLevel
        // ) || {}
      };
      return obj;
    },
    // 是否为子账户
    isSubAccount(state) {
      console.log(state.userinfo.userId, 'state.userinfo.userId');
      console.log(state.userinfo?.masterUserId, 'state.userinfo?.masterUserId');

      return state.userinfo.userId && state.userinfo?.masterUserId !== '0';
    },
    // 是否是银行托管人
    isBankCustodian(state) {
      return state.userinfo?.userType === CONST.USER_TYPE.INSTITUTION && state.userinfo?.etfType == CONST.ETF_TYPE.INSTITUTION;
    },
    // ETF主账户切到子账户，显示4个Tab:ETF账户, 法幣账户, 存管账户, 经纪账户
    isBankCustodianSubAccount(state) {
      return state.userinfo?.userType === CONST.USER_TYPE.INSTITUTION && state.userinfo?.etfType == CONST.ETF_TYPE.INSTITUTION && state.currentSubAccountId;
    },
    // ETF基金，授权交易员身份登录
    isBankAuthTrader(state) {
      return state.userinfo?.userType === CONST.USER_TYPE.AUTHORIZED_TRADER && state.userinfo?.etfType == CONST.ETF_TYPE.INSTITUTION;
    },
    // 判断是不是PD Omnibus 账户
    isPDOmnibusMainAccount(state) {
      return state.userinfo?.userType === CONST.USER_TYPE.OMNIBUS && state.userinfo?.etfType == CONST.ETF_TYPE.OMNIBUS;
    },
    // 判断是不是PD Omnibus 的第一个子账户client account
    isPDOmnibusClientAccount(state) {
      // return true;
      return state.userinfo?.userType === CONST.USER_TYPE.OMNIBUS && state.userinfo?.etfType == CONST.ETF_TYPE.SUB_OMNIBUS;
    },
    isPDOmnibusAccount(state) { // 判断是不是PD Omnibus （包括 omnibus 子账户）
      return state.userinfo?.userType === CONST.USER_TYPE.OMNIBUS && (state.userinfo?.etfType == CONST.ETF_TYPE.OMNIBUS || state.userinfo?.etfType == CONST.ETF_TYPE.SUB_OMNIBUS);
    },
    isOrgAccount(state) {
      // 包含企业 和 omnibus
      return state.userinfo.userId && (state.userinfo.userType == 2 || state.userinfo.userType == 8 || state.userinfo.userType == 4);
    },
    isRetailEtfAccount(state) { // 零售展示ETF账户判断
      return state.userinfo.userId && state.userinfo?.userType === CONST.USER_TYPE.RETAIL && state.userinfo?.tempEtf == CONST.RETAIL_ETF_TYPE.ETF;
    },
    // 是否授权管理员
    isAuthorizedAdmin(state) {
      return state.userinfo.userType === CONST.USER_TYPE.AUTHORIZED_ADMIN;
    },
    // PD下的授权管理员
    isPDAuthorizedAdmin(state) {
      return state.userinfo.userType === CONST.USER_TYPE.AUTHORIZED_ADMIN &&  state.userinfo.masterUserType === CONST.USER_TYPE.OMNIBUS && state.userinfo?.etfType == CONST.ETF_TYPE.OMNIBUS;
    },
    // 普通 Omnibus 下的授权管理员
    isNormalOmnibusAuthorizedAdmin(state) {
      return state.userinfo.userType === CONST.USER_TYPE.AUTHORIZED_ADMIN && state.userinfo.masterUserType === CONST.USER_TYPE.OMNIBUS && state.userinfo?.etfType == 0;
    },
    // 托管人下的授权管理员
    isBankCustodianAuthorizedAdmin(state) {
      return state.userinfo.userType === CONST.USER_TYPE.AUTHORIZED_ADMIN &&  state.userinfo.masterUserType === CONST.USER_TYPE.INSTITUTION && state.userinfo?.etfType == CONST.ETF_TYPE.INSTITUTION;
    },
    // 托管人-ETF主账户切到子账户，显示4个Tab:ETF账户, 法幣账户, 存管账户, 经纪账户
    isBankCustodianAuthAdminSubAccount(state) {
      return state.userinfo.userType === CONST.USER_TYPE.AUTHORIZED_ADMIN &&  state.userinfo.masterUserType === CONST.USER_TYPE.INSTITUTION && state.userinfo?.etfType == CONST.ETF_TYPE.INSTITUTION && state.currentSubAccountId;;
    },
    // 企业授权管理员
    isCorporateAuthorizedAdmin(state) {
      return state.userinfo.userType === CONST.USER_TYPE.AUTHORIZED_ADMIN && state.userinfo.masterUserType === CONST.USER_TYPE.CORPORATE;
    },
    // 普通机构授权管理员
    isNormalInstitutionAuthorizedAdmin(state) {
      return state.userinfo.userType === CONST.USER_TYPE.AUTHORIZED_ADMIN && state.userinfo.masterUserType === CONST.USER_TYPE.INSTITUTION && state.userinfo?.etfType == 0;
    },
    masterOperator(state) {
      // 管理员 作为 授权交易员
      return {
        nickname: state.userinfo.nickname,
        userIdStr: state.userinfo.userId,
        email: state.userinfo.email,
        userStatus: 1,
        defaultAccountIdStr: state.userinfo.defaultAccountId
      };
    },
    /**
     * 根据2FA绑定信息判断用户安全等级
     * HKEX-10817 需要绑定GA, 邮箱/手机可二选一
     * @param state
     * @returns -1 未绑定GA, 1 绑定GA, 邮箱/手机每绑定一个多1
     *
     */
    safeLevelCustody(state): AUTH_SAFE_CUSTODY_LEVEL {
      let safeLevel;
      if (!state.userinfo.bindGA) {
        safeLevel = -1;
      } else {
        safeLevel = 1;
        if (state.userinfo.email) {
          safeLevel += 1;
        }
        if (state.userinfo.mobile) {
          safeLevel += 1;
        }
      }
      return safeLevel;
    }
  },
  actions: {
    changeForbiddenIp(val: string) {
      this.forbiddenIp = val;
    },
    changeLoginOtherEquipment(val: boolean) {
      this.loginOtherEquipment = val;
    },
    changeLoginStatus(val: boolean) {
      this.loginStatusExpire = val;
    },
    changeVpnWarningInfo(val: any) {
      this.vpnWarningInfo = { ...this.vpnWarningInfo, ...val };
    },
    changeOutStatus(val: boolean) {
      this.activeOutStatus = val;
    },
    changeCurrentTime(val: number) {
      this.activeCurrentTime = val;
    },
    // 监控用户长时间无操作自动登出
    checkUserActive(isTrade = false) {
      // 未登录
      if (!this.isLogin) return;
      // 会话超时后没有登出
      if (localStorage.getItem('user_login_expire')) {
        this.activeOutStatus = false; // 关闭会话超时弹窗
        localStorage.removeItem('user_login_expire'); // 删除会话超时的标记
        this.logOut();
        return;
      }

      this.clearTimer();
      this.isTradePage = isTrade;
      // 监听鼠标
      document.addEventListener('mousemove', this.initActiveCount);
      // 监听键盘
      document.addEventListener('keydown', this.initActiveCount);
      // 监听Scroll
      document.addEventListener('scroll', this.initActiveCount);
      // 设置定时器
      this.setTimer();
    },
    // 初始化activeCount
    initActiveCount() {
      this.activeCount = 0;
    },
    // 清除监听和定时器
    clearTimer() {
      document.removeEventListener('mousemove', this.initActiveCount);
      document.removeEventListener('keydown', this.initActiveCount);
      document.removeEventListener('scroll', this.initActiveCount);
      WorkerTimer.clearInterval(this.activeTimer);
      this.activeTimer = null;
      this.activeCount = 0;
    },
    // 定时器
    setTimer() {
      this.activeCount = 0;
      if (!this.activeTimer) {
        this.activeTimer = WorkerTimer.setInterval(this.cookieTimeout, 1000);
      }
    },
    // 超时判断
    cookieTimeout() {
      this.activeCount++;
      let outTime = 0;
      if (this.activeCurrentTime == 2) {
        // time2
        outTime = this.activeOutTime.notTradePageV2;
        if (this.isTradePage) outTime = this.activeOutTime.tradePageV2;
      } else {
        // time1
        outTime = this.activeOutTime.notTradePage;
        if (this.isTradePage) outTime = this.activeOutTime.tradePage;
      }
      // time1不存在，不显示弹窗， time2不存在，不展示2fa
      if (!outTime) {
        this.clearTimer();
        return;
      }
      // 倒计时结束
      if (this.activeCount >= Number(outTime) * 60) {
        // 超时登出
        this.clearTimer();
        if (this.isTradePage) {
          if (this.activeCurrentTime == 1) {
            // 交易页面time2
            this.activeCurrentTime = 2;
            this.checkUserActive(true); // 第二轮计时
          } else {
            // time2倒计时结束
            this.activeCurrentTime = 3;
          }
        } else {
          if (this.activeCurrentTime == 1) {
            // 非交易页面time2
            this.activeCurrentTime = 2;
            this.checkUserActive(); // 第二轮计时
          } else {
            // time2倒计时结束
            this.activeCurrentTime = 3;
          }
          this.activeOutStatus = true;
        }
      }
    },
    // 改变out time
    changeOutTime(val: any) {
      this.activeOutTime = val;
    },
    async getUserinfo() {
      this.loading.userInfo = true;
      return new Promise(async resolve => {
        const data: any = await ucApi.userinfo();

        this.loading.userInfo = false;

        if (data.code === 200) {
          if (data.data?.userType === CONST.USER_TYPE.AUTHORIZED_ADMIN && data.data.hasOwnProperty('masterInfo')) {
            // 数据包含 masterInfo 则表明授权管理员存在主账户,没有该字段，则主账户不存在
            this.hasMainPermission = true;
            const { nickname, userIdStr, defaultAccountIdStr, userStatus, email } = data.data.masterInfo || {};
            this.mainPermissionBaseInfo = { nickname, userIdStr, defaultAccountIdStr, userStatus, email };
            // this.currentSubAccountId = this.mainPermissionBaseInfo.userIdStr || '';
          } else if (data.data?.userType === CONST.USER_TYPE.AUTHORIZED_ADMIN && !data.data.hasOwnProperty('masterInfo')) {
            // 没有绑定主账户时，获取已绑定子账户列表内第一个账户，并存在 localStorage 内
            this.hasMainPermission = false;
            this.mainPermissionBaseInfo = {};
            // 子账户信息信息优先从 localStorage 中的 sub_account_list 获取数据，如果没有，则重新拉最新首页数据
            let alreadyHas = false;
            if (this.currentSubAccountId) {
              const localSubAccountList = JSON.parse(localStorage.getItem('sub_account_list') || '[]');
              if (Array.isArray(localSubAccountList)) {
                const item = localSubAccountList.find((item: any) => item.userIdStr === this.currentSubAccountId);
                alreadyHas = item !== undefined;
              }
            }
            if (!this.currentSubAccountId || !alreadyHas) {
              // 没有存储的子账户，则获取子账户列表，并将返回数据的第一个子账户进行展示
              const params = {
                keyword: '',
                pageNum: 1,
                pageSize: 5,
                userType: 5, // 请求子账户，固定值 5
                matchTrader: true, // 是否需要过滤掉已授权交易员的子账户，true 过滤，false 不过滤 [默认是过滤]
                authAdminSubList: true
              };
              if (data.data?.masterUserType === CONST.USER_TYPE.INSTITUTION && data.data?.etfType == CONST.ETF_TYPE.INSTITUTION) {
                // 如果该授权管理员是ETF下的授权管理员，需要将 matchTrader 置为 false
                params.matchTrader = false;
              }
              const res: any = await this.getSubAccountList(params);
              if (res.success && res?.data?.data && res?.data?.data?.length) {
                const item = res?.data?.data[0];
                if (item?.userIdStr) {
                  this.currentSubAccountId = item?.userIdStr;
                  localStorage.setItem('sub_account', item?.userIdStr);
                }
              }
            }
          }
          // 如果是授权管理，则获取对应权限
          let riskRes:any = undefined;
          if (data.data?.userType === CONST.USER_TYPE.AUTHORIZED_ADMIN) {
            riskRes = await ucApi.getUserRiskInfo();
          }
          if (riskRes && riskRes?.code === 200) {
            (data.data || {}).riskSettings = riskRes?.data;
          }
          this.userinfo = data.data || {};

          // 请求子账户
          this.getSubAccountList();
          this.getIsOPTAccount();
          if (!this.loginStateTimer) {
            this.loginStateLoop();
          }
          resolve(true);
        }

        resolve(false);
      });
    },
    loginStateLoop() {
      this.loginStateTimer = setTimeout(async() => {
        if (this.isLogin) {
          const res: any = await ucApi.isLogin();

          if ((res.success && res.data.userId) || res.code === 500) {
            this.loginStateLoop();
          } else {
            this.logOut();
          }
        } else {
          clearTimeout(this.loginStateTimer);
        }
      }, 5000);
    },
    clearUser() {
      orderStore.clearOrder(); // 登出后清楚轮询接口
      removeCookie('account_id');
      removeCookie('au_token');
      removeCookie('c_token');
      removeCookie('user_id');
      // removeCookie('glb_account_id');
      // removeCookie('glb_au_token');
      // removeCookie('glb_c_token');
      // removeCookie('glb_user_id');
      // removeCookie('group_user_id');
      // removeCookie('group_auth_token');
      localStorage.removeItem('sub_account');
      localStorage.removeItem('ws_sub_account');
      localStorage.removeItem('address_verify_visible');
      localStorage.removeItem('already_verify_modal');
      localStorage.removeItem('forbidden-ip');
      localStorage.removeItem('sub_account_list');
      window.sessionStorage.removeItem('userInfo');
      window.sessionStorage.removeItem('last_show_time');
      window.sessionStorage.removeItem('kycStatus');
      this.userinfo = {} as Userinfo;
      this.userHskFeeInfo = {} as UserHskFeeInfo;
      this.currentSubAccountId = '';
      this.isOptInited = false;
      this.optPendingSettleInfo = {} as PendingSettleInfo;
    },
    logOut(clearOnly = false) {
      if (!this.isLogin) return;
      // ucApi.passportLogout({});
      ucApi.logout({}).then((res: any) => {
        if (res.success) {
          // 登出成功
          const userType = this.userinfo.userType;
          this.clearUser(); // 清除用户信息
          // 登录页面，提示登录超时时，提示会话超时时，提示其他设备登录时，不进行跳转
          if (clearOnly || location.pathname.includes('/login') || this.loginStatusExpire || this.activeOutStatus || this.loginOtherEquipment) {
            return;
          }

          const { search, pathname } = window.location;

          const isStay = stayRoute.some((item: any) => {
            const path = getOriginPath(pathname);
            if (item instanceof RegExp) {
              return item.test(path);
            } else {
              return item === path;
            }
          });

          setTimeout(() => {
            // 退出登录接口
            // 留在当前页面
            if (isStay) {
              // 避免连续刷新 死循环
              if (new Date().getTime() - lastRefresh.getTime() > 1000 * 5) {
                lastRefresh = new Date();
                window.location.reload();
              }
            } else {
              if (pathname.replace(/^\/\w{2}-\w{2}\//, '/') == '/user/kycaudit') {
                window.location.href = '/login?backurl=/user/kyc';
              } else {
                window.location.href = `/login?userType=${userType || 0}&backurl=${pathname.replace(/^\/\w{2}-\w{2}\//, '/')}${search}`;
                // 退出登录不给backurl
                // window.location.href = '/login';
              }
            }
          }, 10);
        } else {
          // 登出失败
          toast.error(res.message);
        }
      });
    },
    // 登出并且去登录页
    logOutAndRedirect() {
      if (!this.isLogin) return;
      // ucApi.passportLogout({}); // 清除passport的cookie
      ucApi.logout({}).then((res: any) => {
        if (res.success) {
          // 登出成功
          this.clearUser(); // 清除用户信息
          // 去登录页
          const { search, pathname } = window.location;
          window.location.href = `/login?backurl=${pathname.replace(/^\/\w{2}-\w{2}\//, '/')}${search}`;
        } else {
          // 登出失败
          toast.error(res.message);
        }
      });
    },
    // 强制调用登出
    forceLogOut(callback: () => void) {
      ucApi.logout({}).then((res: any) => {
        if (res.success) {
          // 登出成功
          this.clearUser(); // 清除用户信息
          callback();
        } else {
          callback();
        }
      });
    },
    getCountries() {
      if (this.countriesList.length) {
        return;
      }
      // 国家区号列表
      ucApi.getCountries({ businessType: 9 }).then((data: any) => {
        this.countriesList = [];
        if (data.code === 200) {
          data.data.countries
            // .filter((item:any) => item.nationalCode === "86")
            .forEach((item: never) => {
              this.countriesList.push(item);
            });
        }

      });
    },
    getCountry() {
      if (this.countryList.length) {
        return;
      }
      // 国家区号列表
      ucApi.getCountries({ businessType: 8 }).then((data: any) => {
        this.countryList = [];
        if (data.code === 200) {
          data.data.countries
            // .filter((item:any) => item.nationalCode === "86")
            .forEach((item: never) => {
              this.countryList.push(item);
            });
        }

      });
    },
    // 获取注册页面手机号支持国家列表
    getRegCountries() {
      if (this.regCountriesList.length) {
        return;
      }
      // 国家区号列表
      ucApi.getRegCountries({ businessType: 9 }).then((data: any) => {
        this.regCountriesList = [];
        if (data.code === 200) {
          data.data.countries
            // .filter((item:any) => item.nationalCode === "86")
            .forEach((item: never) => {
              this.regCountriesList.push(item);
            });
        }

      });
    },
    // 获取注册页面禁用手机号码注册国家列表
    getUnRegCountries() {
      if (this.regCountriesList.length) {
        return;
      }
      // 国家区号列表
      ucApi.getRegCountries({ businessType: 104 }).then((data: any) => {
        this.unRegCountriesList = [];
        if (data.code == 200 && data?.data?.length > 0) {
          data.data
            // .filter((item:any) => item.nationalCode === "86")
            .forEach((item: never) => {
              this.unRegCountriesList.push(item);
            });
        }
      });
    },
    // 设备信息
    getRegDeviceInfo() {
      // 设备信息
      ucApi.getDeviceInfo().then((data: any) => {
        if (data.code == 200 && data?.data && JSON.stringify(data?.data) != '{}') {
          this.regDeviceInfo = data.data;
        } else {
          // 没有获取设备信息时，设置默认的countryCode
          this.regDeviceInfo = { countryCode: '' };
        }
      });
    },
    /**
     * 获取kyc信息
     */
    requestKycInfo() {
      kycApi.getUserKycInfo()
        .then((data: any) => {
          if (data.success) {
            this.kycInfo = data.data;
            localStorage.setItem('kycInfo', JSON.stringify(data.data));
          }
        });

    },
    /**
     * 验证信息
     */
    sendVerifyInfo() {
      userApi.getVerifyInfo()
        .then((data: any) => {
          if (data.success) {
            this.verifyInfo = data.data;
          }
        });
    },
    /**
     * 获取2FA绑定信息
     */
    async get2FAInfo() {
      const res: any = await userApi.check2FA();
      if (res.code === '0') {
        this.bindInfo = res.data;
      } else {
        toast.error(res.message);
      }
      return res;
    },
    /**
     * 请求子账户列表
     *
     * @param isTransfer 是否是划转，在划转时请求子账户如果已有子账户那么不用重复请求
     * @returns
     */
    async sendtSubAccount(isTransfer = false, fullMatch = false, params = {}) {
      if (!fullMatch && isTransfer && this.subAccount && this.subAccount.length) {
        return;
      }

      let res:any = undefined;

      if (fullMatch) {
        res = await etfApi.fullMatchAccount(params);
      } else {
        res = await assetApi.sendSubAccount();
      }

      if (res.success) {
        this.subAccount = res.data;

        // 子账户map
        // res.data.forEach((item: any) => {
        //   if (!this.subAccountMap[item.accountId]) {
        //     this.subAccountMap[item.accountId] = item;
        //   }
        // });
      }
    },
    async getInviteCode() {
      const res: any = await userApi.getInviteCode({});
      if (res.success) {
        this.inviteCode = res.data.inviteCode;
      }
    },
    async getSubAccountList(params = { pageNum: 1, pageSize: 10, userType: 5, keyword: '' }) {
      // 获取全部数据时pageSize传1000，超过999
      const res: any = await subApi.getSubListPaging(params);
      if (res.success) {
        // this.subAccountList = res.data.data;
        const localSubAccountListStr  = localStorage.getItem('sub_account_list');

        if (localSubAccountListStr) {
          try {
            this.subAccountList = JSON.parse(localSubAccountListStr);
          } catch (error) {
            console.log('error', 'localSubAccountListStr 解析失败');
          }
        }

        this.subAccountList = [...(res.data.data || []), ...this.subAccountList];

        const uniqueObjects = this.subAccountList.reduce((acc: any, obj: any) => {
          if (!acc.some((item: any) => item.userIdStr === obj.userIdStr)) {
            acc.push(obj);
          }
          return acc;
        }, []);

        localStorage.setItem('sub_account_list', JSON.stringify(uniqueObjects));
        this.subAccountList = uniqueObjects;
      }

      return res;
    },
    async getOperatorList(params = { pageNum: 1, pageSize: 1000, userType: 6 }) {
      // 获取全部数据时pageSize传1000，超过999  userType 5为交易员 6 操作员，不传为全部
      const res: any = await subApi.getSubListPaging(params);
      if (res.success) {
        this.operatorList = res.data.data;
      }
    },
    async getApiKeyAuthList() {
      const res: any = await subApi.getApiKeyAuthList({});
      if (res.success) {
        this.apiKeyAuthList = res.data;
      }
    },
    // 获取用户等级信息
    async getLevelInfo() {
      const res: any = await vipApi.levelConfigs();
      if (res.success) {
        this.levelInfo = res.data;
      }
    },
    switchSubAccount(subAccount: any, toPath = '', isNotReload:any) {
      if (subAccount && subAccount.userIdStr !== this.userinfo.userId) {
        this.currentSubAccountId = subAccount.userIdStr;
        localStorage.setItem('sub_account', this.currentSubAccountId);
      } else {
        this.currentSubAccountId = '';
        localStorage.removeItem('sub_account');
      }
      if (toPath) {
        window.location.replace(toPath);
      } else {
        if (isNotReload) {

        } else {
          window.location.reload();
        }
      }
      assetsStore.getTotalAsset();

    },
    async getIsOPTAccount() {

      userApi.queryAccountList({}).then(async(res: any) => {
        if (res.success) {
          const isOpt = res.data?.filter((item:any) => item.accountType == CONST.ACCOUNT_TYPE.OPT).length > 0;
          // 存在OPT账户
          if (isOpt) {
            this.isOPTAccount = isOpt;
            await this.getPendingSettleInfo();
          }

          // 存在处置账户
          const isDisposalAccount = res.data?.filter((item:any) => item.accountType == CONST.ACCOUNT_TYPE.OPT_DISPOSAL).length > 0;

          this.isDisposalAccount = isDisposalAccount;// 判断是否是处置账户
        }
      }).finally(() => {
        this.isOptInited = true;
      });
    },
    // HKEX-9084 TODO 灰度用户查询冻结余额
    async getPendingSettleInfo() {
      const pendingSettleAmountRes: any = await rfqApi.fetchPendingSettleAmount();
      this.optPendingSettleInfo = pendingSettleAmountRes?.data || {};
    },
    addRefreshFlag() {
      this.refresh++;
      this.getSubAccountList();
      assetsStore.getSubAccountTotal();
      this.getOperatorList();
    },

    // 获取用户HSK等级相关信息
    async getUserHskFeeInfo() {
      try {
        const res: any = await hskFeeApi.getUserVipInfo({});
        if (res && String(res?.code) === '200') {
          const info = res?.data || {};
          this.userHskFeeInfo = { ...info };
        }
      } catch {}
    },

    // 获取用户权限
    async getUser() {
      try {
        const res: any = await hskFeeApi.getUserVipInfo({});
        if (res && String(res?.code) === '200') {
          const info = res?.data || {};
          this.userHskFeeInfo = { ...info };
        }
      } catch {}
    },
    // // 查询授权管理员是否绑定主账户权限
    // async getMainPermission() {
    //   try {
    //     const res: any = await authorizedAdminApi.getMainAccountInfo();
    //     if (res && String(res?.code) === '200') {
    //       if (res?.data && res.data?.hasPermission) {
    //         this.hasMainPermission = true;
    //         const { nickname, userIdStr, defaultAccountIdStr, userStatus } = res.data?.info || {};
    //         this.mainPermissionBaseInfo = { nickname, userIdStr, defaultAccountIdStr, userStatus };
    //       } else {
    //         this.hasMainPermission = false;
    //         this.mainPermissionBaseInfo = {};
    //       }
    //     }
    //   } catch (e: any) {
    //     this.hasMainPermission = false;
    //     this.mainPermissionBaseInfo = {};
    //   }
    // },

    // 授权管理员获取权限
    async getAuthorizedAdminPermission() {
      try {
        const res: any = await authorizedAdminApi.getCurrentAccountPermission({});
        if (res && String(res?.code) === '200') {
          const info = res?.data || [];

          // const tempMap:{[key: string] : any} = { // 返回的key与本地的不匹配，做个映射
          //   asset: 'overView',
          //   trade: 'trading',
          //   // order: 'trading
          //   subaccount: 'subAccount'
          // };

          this.permissions = info.map((item: any) => {
            // if (tempMap.hasOwnProperty(item?.permKey)) {
            //   item.permKey = tempMap?.[item?.permKey];
            // }

            if (!item.hasPermission && item.children?.length > 0) { // 如果一级节点是false并且有子节点，需要判断子节点有没有true
              const finalPermission = item.children.some((k: any) => k.hasPermission);
              item.hasPermission = finalPermission;
            }
            // this.dealPermission(item);

            return item;
          });
        }
      } catch {}
    },

    // 获取导航栏资产下拉列表 / 资产页tabs数组 / 资产总览页中账户列表
    getAccountList(isTabs = false) {
      if (this.isAuthorizedAdmin) {
        const res = this.permissions?.filter((item: any) => item?.hasPermission) || [];

        const tempMap: {[key: string]: string} = { // 返回的key与本地的不匹配，做个映射
          asset: 'overView',
          trade: 'trading',
          // order: 'trading
          subaccount: 'subAccount'
        };

        const totalList = ['overView', 'etf', 'optDisposal', 'prefund', 'subAccount', 'custody', 'fiat', 'trading', 'brokerage'];

        const finalRes =  res.map((item: any) => tempMap[item.permKey as keyof typeof tempMap] || item?.permKey);

        // 账户最终只可能是finalList的子集
        const finalList = finalRes?.filter((key: string) => totalList.some((k: string) => k === key));

        return finalList;
      }

      // 当是ETF授权交易员登录的时候
      if (this.isBankAuthTrader) {
        return ['etf', 'brokerage'];
      }
      // 当前账户是ETF银行托管人
      if (this.isBankCustodian && !this.currentSubAccountId) {
        return isTabs ? ['overView', 'etf'] : ['etf', 'subAccount'];
      }
      // 当前切换到子账户
      if (this.currentSubAccountId) {
        if (this.currentSubAccount?.etfType == CONST.ETF_TYPE.INSTITUTION) {
          // 当是ETF托管人子账号的时候，展示 存管账户, 法幣账户, ETF账户, 经纪账户
          return isTabs ? ['overView', 'custody', 'fiat', 'etf', 'brokerage'] : ['custody', 'fiat', 'etf', 'brokerage'];
        } else if (this.currentSubAccount?.etfType == CONST.ETF_TYPE.SUB_OMNIBUS) {
          // 当是PD omnibus 子账户资金归集账户展示 存管账户, 交易账户, ETF账户
          return isTabs ? ['overView', 'custody', 'trading', 'etf'] : ['custody', 'trading', 'etf'];
        } else if (this.currentSubAccount.etfType == CONST.ETF_TYPE.OMNIBUS) {
          // 当是PD omnibus 普通子账户, 展示 存管账户
          return isTabs ? ['overView', 'custody'] : ['custody'];
        }
        if (this.currentSubAccount?.userIdStr !== this.userinfo?.userId) {
          // 普通子账户，只展示 交易账户
          return isTabs ? ['trading'] : [];
        }
      }
      // 普通授权交易员 且非 ETF授权交易员，且没绑定任何子账户
      if (this.userinfo.userType === CONST.USER_TYPE.AUTHORIZED_TRADER && this.userinfo.etfType !== CONST.ETF_TYPE.INSTITUTION) {
        return [];
      }
      /** 以上 是根据用户类型，直接返回具体数组； 以下 则是对所有用户做的通用处理，只是个别类型时需要特殊处理 */

      // 如果当前用户是授权管理员，直接返回有权限的一级即可
      const accList = ['custody', 'fiat', 'trading', 'brokerage'];

      // omnibus 综合账户
      if (this.userinfo.userType === CONST.USER_TYPE.OMNIBUS) {
        // omnibus 添加预存金
        accList.push('prefund');
        if (this.userinfo.etfType === CONST.ETF_TYPE.OMNIBUS) {
          // PD Omnibus 主账户: 存管账户, 法幣账户, 交易账户, 预存金账户, ETF账户
          accList.push('etf');
        } else {
          // 普通 omnibus 没有存管账户
          accList.shift();
        }
      }
      // 存在处置账户
      if (this.isDisposalAccount) {
        accList.push('optDisposal');
      }
      // 零售ETF账户，添加 etf 账户
      if (this.isRetailEtfAccount) {
        accList.push('etf');
      }
      // 账户存在子账户，则 nva 和 over,  tabs 内不显示子账户tab
      if (this.subAccountList && this.subAccountList.length > 0 && !isTabs) {
        accList.push('subAccount');
      }
      // 如果是tabs，且经纪账户未开通，则不在 tabs 内显示经纪账户
      if (isTabs && !this.isOPTAccount) {
        const idx = accList.indexOf('brokerage');
        if (idx !== -1) {
          accList.splice(idx, 1);
        }
      }
      // tabs 内添加 overView
      if (isTabs) {
        accList.unshift('overView');
      }

      return accList;
    }
  }
});
