import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import proxy from '@/config/proxy';
import type { ParamsType } from '@/config/proxy';
import { getCookie } from '@/utils/cookie';
import { isApp, isPC, isAndroid, isIOS } from '@/utils/tools';
import { pinia } from '@/store/index';
import { useUserStore } from '@/store/User';
import { getLanguage } from '@/lang/i18n';
import { ucApi } from '@/config/api';
import eventTrack from '@/utils/eventTracking';
import { needLoginApi } from '@/config/needLoginApi';
// import { sentryGatewayExceptionUpload } from '@/utils/sentry';
import sha256 from 'js-sha256';

const userStore = useUserStore(pinia);

const Axios = axios.create({
  timeout: 30000, // 30秒
  // headers: {
  //   'Accept-Language': getLanguage().toLowerCase()
  // },
  // 跨越请求凭证
  withCredentials: true
});

const matchConfig = (config: AxiosRequestConfig): ParamsType => {
  const cache: ParamsType = {
    url: config.url || ''
  };

  Object.keys(proxy).some(key => {
    if (cache.url.indexOf(key) === 0) {
      const { url, headers = {}, params, responseHandle } = proxy[key];

      cache.url = cache.url.replace(key, url);
      cache.headers =
        typeof headers === 'function' ? headers(config.headers) : headers;
      cache.params =
        typeof params === 'function' ? params(config.params) : params;
      cache.responseHandle =
        typeof responseHandle === 'function' ? responseHandle : undefined;

      return true;
    }

    return false;
  });

  return cache;
};

// type TypeRequestHandle = {
//   responseHandle?: ParamsType['responseHandle'];
// } & AxiosRequestConfig;

/**
 * 请求参数处理
 *
 * @param {object} config 请求参数
 * @returns {object} 处理后的参数
 */
const requestHandle = (config: any) => {
  const { url, headers, params, responseHandle } = matchConfig(config);
  // 打点接口请求时间
  config.metadata = { startTime: new Date().getTime() };

  config.url = url;
  config.responseHandle = responseHandle;

  config.headers = {
    ...config.headers,
    'Accept-Language': getLanguage().toLowerCase(),
    'device-detail-type': isPC() ? 'Web' : (isIOS() ? 'H5-IOS' : (isAndroid ? 'H5-Android' : 'Other'))
  };

  // 设置post请求时默认Content-Type
  if (config.method === 'post' && !config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'multipart/form-data';
  }

  // 处理 headers
  if (headers) {
    config.headers = { ...headers, ...config.headers };
  }
  // 处理 params
  if (params) {
    config.params = { ...params, ...config.params };
  }

  config.params = {
    ...config.params,
    r: Math.random().toString(32).substring(2),
    c_token: getCookie('c_token')
  };

  if (localStorage.getItem('sub_account')) {
    config.headers = {
      ...config.headers,
      'sub-user-id': localStorage.getItem('sub_account')
    };
  }
  // 可信设备
  if (localStorage.getItem('device-info')) {
    const deviceInfo = JSON.parse(localStorage.getItem('device-info'));
    config.headers = {
      ...config.headers,
      'Fimageid': deviceInfo?.device_id || '',
      'Fdeviceid': sha256(new Date().getTime().toString()),
      'Device-Name': deviceInfo?.device_name || '',
      'Device-Model': deviceInfo?.device_model || ''
    };
  }
  // 当接口在list中的时候，未登录不请求接口 start
  const isUrlInList = needLoginApi.some((url:string) => config.url?.includes(url));
  if (isUrlInList && !userStore.isLogin) {
    return Promise.reject(new Error('User is not logged in'));
  }
  // 当接口在list中的时候，未登录不请求接口 end

  return config;
};

/**
 * 请求结果处理
 *
 * @param {object} response axios返回的结果
 * @returns {object} 处理之后的结果
 */
const responseHandle = (response: AxiosResponse | any) => {
  const data = { ...response.data };
  try {
    var reqURL = response.request.responseURL;
    var RT = new Date().getTime() - response.config.metadata.startTime;
    if (import.meta.env.MODE == 'production') {
      if (reqURL.includes('api/quote/v1/rates')) {
        eventTrack({ name: 'webPerformance-RT2-Cycle-Rates', data: { value: RT,  reqURL } });
      } else {
        if (RT < 4000) {
          eventTrack({ name: 'webPerformance-RT2', data: { value: RT,  reqURL } });
        } else {
          eventTrack({ name: 'webPerformance-RT2-EXCP', data: { value: RT,  reqURL } });
        }
      }
    }
  } catch (e) { }

  // console.log("reqURL:", response.request.responseURL, "RT:", new Date().getTime() - response.config.metadata.startTime);
  type IPlanTagProps = {
    success: boolean;
    code?: number;
    message?: string;
    data: object;
  };
  // console.log(response.data, 'response.data');
  const newData: IPlanTagProps = {
    success: response.data && response.data.code ? response.data.code == 200 : response.status == 200,
    code: response.data && response.data.code ? response.data.code : response.status,
    message: response.data && (response.data.msg || response.data.message) ? (response.data.msg || response.data.message) : (response.message || response.msg || response.error || 'success'),
    data: response.data && response.data.data ? response.data.data : response.data // 处理返回成功时带code情况
  };

  // const newData: IPlanTagProps = {
  //     success:  data.success || (data.code >= 200 && data.code <= 300) || data.status === 'ok' || (data.code ? false : true),
  //     code: data.code || 200,
  //     message: data.message || data.error || 'success',
  //     data: response.data,
  // };
  // 如果代理有处理相应函数优先使用
  // eslint-disable-next-line
  // @ts-ignore

  if (response.config.responseHandle) {
    // eslint-disable-next-line
    // @ts-ignore
    return response.config.responseHandle(data, response);
  }

  return newData;
};

/**
 * 错误处理
 *
 * @returns {object} 处理之后的结果
 */
const errorHandle = (error: any) => {
  // 判断登录状态是否过期
  if (error?.response?.data?.code === 200008) {
    // 登录状态过期
    userStore.changeLoginStatus(true);
    userStore.logOut(true);
  }
  // 判断是否其他设备登录
  if (error?.response?.data?.code === 200263 && userStore.isLogin) {
    // 登录状态过期
    userStore.changeLoginOtherEquipment(true);
    userStore.logOut(true);
  }
  // vpn提示弹窗
  if ([200012, 200013, 200014].includes(error?.response?.data?.code) && !userStore?.vpnWarningInfo?.visible) {
    ucApi.vpnBlocked({}).then((info: any) => {
      if (info.code == 200) {
        let userLevel = 1;
        if (error?.response?.data?.code === 200013) userLevel = 2;
        if (error?.response?.data?.code === 200014) userLevel = 3;
        // 6233 vpn提醒lv1弹窗出现undefined，预期：接口拿不到数据就不弹窗
        if ((userLevel == 1 && !info?.data?.vpnRemainDay) || (userLevel == 2 && !info?.data?.certId)) return;
        userStore.changeVpnWarningInfo({ ...info.data, visible: true, userLevel: userLevel });
      }
    });
  }
  // vpn拦截页
  if (!location.pathname.includes('/forbidden/vpn') && error?.response?.data?.code === 200009) {
    location.href = '/forbidden/vpn';
  }
  // ip拦截页
  if (!location.pathname.includes('/forbidden/ip') && (error?.response?.data?.code === 200010 || error?.response?.data?.code === 200011)) {
    location.href = '/forbidden/ip';
  }
  // 地区拦截
  if (error?.response?.data?.code === 200006) {
    localStorage.setItem('forbidden-ip', error?.response?.data?.msg);
    userStore.changeForbiddenIp(error?.response?.data?.msg);
    if (!location.pathname.includes('/forbidden/area')) {
      location.href = '/forbidden/area';
    }
  }

  if (error.request.responseURL
    && error.request.responseURL.indexOf('/user/get_base_info') <= 0
    && error.response.data.code === 30000
  ) {
    if (!isApp() && userStore.isLogin) {
      userStore.changeOutStatus(false); // 关闭会话超时弹窗(保证被动登出时，关闭该弹窗)
      userStore.logOut();
    }
  }

  let errMsg = 'The system is busy, please try again later.';
  if (getLanguage().toLowerCase() == 'zh-tw') errMsg = '系統繁忙，請稍後重試。';
  if (getLanguage().toLowerCase() == 'zh-cn') errMsg = '系统繁忙，请稍后重试。';

  // 接口网关异常上报
  // sentryGatewayExceptionUpload(error);

  // 接口没有error message的情况下
  // 接口30s超时场景  The system is busy, please try again later.（timeout）
  // 后端有errorCode 场景 The system is busy, please try again later.（errorCode）
  // 后端没有errorCode 场景 The system is busy, please try again later.（httpCode）
  const urlNames = error?.config?.url.split('?')[0]?.split('/') || [];
  let urlName = ''; // 接口的name
  if (urlNames.length > 0) urlName = urlNames[urlNames.length - 1];
  let defaultErrMsg = `${errMsg}`;
  if (error?.code === 'ECONNABORTED') {
    // 超时
    defaultErrMsg = `${errMsg}（timeout ${urlName}）`;
  } else if (error?.response?.data?.code) {
    // 有error code
    defaultErrMsg = `${errMsg}（${error?.response?.data?.code} ${urlName}）`;
  } else if (error?.response?.status) {
    // 没有error code
    defaultErrMsg = `${errMsg}（${error?.response?.status} ${urlName}）`;
  }

  return {
    success: false,
    code: error?.response?.data?.code || 500,
    data: {
      msg: error?.response?.data?.msg || defaultErrMsg
    },
    message: error?.response?.data?.msg || defaultErrMsg,
    error
  };
};

// 请求参数处理
Axios.interceptors.request.use(
  config => requestHandle(config),
  error => errorHandle(error)
);

// 响应数据处理
Axios.interceptors.response.use(
  response => responseHandle(response),
  error => errorHandle(error)
);

export default Axios;
